import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../store/AppContext";

function UserLogin() {
  const appContext = useContext(AppContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    fetch("/api/users/login.php", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const { status_type, status_msgs, status_data } = data;
        appContext.showAlert({
          status_type: status_type,
          status_msgs: status_msgs,
        });
        if (status_type === "success") {
          appContext.setUserMe(status_data.userMe);
          localStorage.setItem("userMe", JSON.stringify(status_data.userMe));
          return navigate("/dashboard", { state: { path: "Dashboard" } });
        }
      });
  };

  useEffect(() => {
    const userMe = localStorage.getItem("userMe");
    if (userMe) return navigate("/dashboard", { state: { path: "Dashboard" } });
  });

  return (
    <section className="container">
      <div className="input-wrapper">
        <form onSubmit={handleFormSubmit}>
          <div className="input-inline">
            <div className="box-title">
              <h3>Account Login</h3>
            </div>
            <div>
              <label>
                <span>Your username:</span>
                <input
                  type="email"
                  placeholder="Enter email"
                  required
                  ref={emailRef}
                />
              </label>
              <aside>e.g. user@domain.com</aside>
            </div>
            <div>
              <label>
                <span>Your password:</span>
                <input
                  type="password"
                  placeholder="Enter password"
                  required
                  ref={passwordRef}
                />
              </label>
            </div>
            <div>
              <label>
                <span></span>
                <button>Login</button>
              </label>
              <aside>
                <button type="reset">Clear</button>
              </aside>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default UserLogin;
