import { useCallback, useEffect, useRef } from "react";
import "./SmartAlert.css";
let queue = Promise.resolve();

function SmartAlert({ showAlert }) {
  const smartAlertRef = useRef(null);
  const showInProgress = useRef(false);
  const hideInProgress = useRef(false);
  const scrolled = useRef(false);
  const interval = useRef(null);
  const settings = useRef({
    autoClose: [true, 3000],
    showClose: true,
    scrollClose: true,
    classList: ["notice", "success", "error", "warn"],
  });

  //set current alert setting and return a promise resolve
  const setOptions = useCallback((options) => {
    return new Promise((resolve) => {
      //set alert options
      settings.current = { ...settings.current, ...options };
      resolve();
    });
  }, []);

  //listen for transition end event and return a promise resolve
  const transitionEnd = (message) => {
    return new Promise((resolve) => {
      smartAlertRef.current.addEventListener(
        "transitionend",
        (smartAlertRef.current.transitionEndListener = () => {
          smartAlertRef.current.removeEventListener(
            "transitionend",
            smartAlertRef.current.transitionEndListener
          );
          resolve(message);
        })
      );
    });
  };

  //hide the alert
  const hideAlert = useCallback(() => {
    if (smartAlertRef.current.classList.contains("slideup") === false)
      return new Promise(async (resolve) => {
        hideInProgress.current = true;
        smartAlertRef.current.classList.add("slideup");
        await transitionEnd("Hide alert is complete");
        smartAlertRef.current.className = "slideup"; //ensure that no other class exist
        hideInProgress.current = false;
        resolve();
      });
  }, []);

  //hide alert on scroll if set
  const hideOnScroll = useCallback(() => {
    //console.log(scrolled.current, inProgress.current);
    if (
      !scrolled.current &&
      !showInProgress.current &&
      settings.current.scrollClose
    ) {
      //console.log("scrollClose is true, closing...");
      scrolled.current = true;
      hideAlert();
    }
  }, [hideAlert, showInProgress, scrolled]);

  //called from outside with alert and options data to show alerts
  const setShowAlert = useCallback(
    (alertData, options) => {
      //console.log(alertData);
      const { type, alerts } = alertData;
      if (!type || alerts?.length === 0) return null;

      queue = queue.then(async () => {
        if (hideInProgress.current) return null;
        showInProgress.current = true;
        //configure alert options
        await setOptions(options);
        //console.log(settings.current);

        //hide current alert if displayed
        await hideAlert();

        //configure alert
        smartAlertRef.current.querySelector("aside").style.visibility = settings
          .current.showClose
          ? "visible"
          : "hidden";

        smartAlertRef.current.classList.add(type);
        smartAlertRef.current.querySelector("span").innerHTML = "";
        alerts.forEach((element) => {
          const pNode = document.createElement("p");
          pNode.innerText = element;
          smartAlertRef.current.querySelector("span").appendChild(pNode);
        });

        //display alert
        smartAlertRef.current.classList.remove("slideup");
        await transitionEnd("Show transition complete");

        //bind scroll event;
        if (settings.current.scrollClose) {
          window.addEventListener("scroll", hideOnScroll, false);
          scrolled.current = false;
        }
        //unbined scroll if scrollClose is false
        if (!settings.current.scrollClose)
          window.removeEventListener("scroll", hideOnScroll, false);

        //console.log(this.settings.autoClose[0]);
        if (settings.current.autoClose[0]) {
          //clears if previous interval exists
          //prevent execution if previous timeout still exist
          if (interval.current !== null) clearTimeout(interval.current);
          //set time out for auto close
          interval.current = setTimeout(() => {
            //prevent hide if que is still in progress
            if (!showInProgress.current && !hideInProgress.current) hideAlert();
          }, settings.current.autoClose[1]);
          //console.log(this.interval);
        }
        showInProgress.current = false;
      });
    },
    [hideAlert, setOptions, hideOnScroll]
  );

  useEffect(() => {
    showAlert.current = setShowAlert;
  }, [showAlert, setShowAlert]);

  return (
    <div className="smart-alert-box">
      <div className="smart-alert-wrapper">
        <div id="smart-alert" className="slideup" ref={smartAlertRef}>
          <figure></figure>
          <span></span>
          <aside title="close" onClick={hideAlert} />
        </div>
      </div>
    </div>
  );
}

export default SmartAlert;
