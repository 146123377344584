import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function Dashboard() {
  const [options, setOptions] = useState({ accessibility: { enabled: false } });
  const navigate = useNavigate();

  const handleNavClick = useCallback(
    (id) => {
      const query = new URLSearchParams({ chartid: id || 1 });

      fetch("/api/dashboard/get.php?".concat(query))
        .then((response) => {
          if (response.status === 403) {
            localStorage.removeItem("userMe");
            return navigate("/", { state: { path: "Login" } });
          }
          return response.json();
        })
        .then((data) => {
          //console.log(data.status_data.data);
          setOptions(data.status_data.data);
        });
    },
    [navigate]
  );

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("userMe"));
    if (!userMe) return navigate("/", { state: { path: "Login" } });
    handleNavClick();
  }, [handleNavClick, navigate]);

  return (
    <section className="container dashboard">
      <nav className="charity-home-chartnav">
        <article className="navitem item-1" onClick={() => handleNavClick(1)}>
          <div>
            <span>
              <h4>
                <strong>This Month</strong>
              </h4>
              <h4>March 2023</h4>
            </span>
            <span>
              <figure>
                <img src="assets/images/icons/chart-01.png" alt="" />
                <figcaption>
                  <h4>2344 User(s)</h4>
                  <h3>23434 Relay(s)</h3>
                </figcaption>
              </figure>
            </span>
          </div>
        </article>
        <article className="navitem item-2" onClick={() => handleNavClick(2)}>
          <div>
            <span>
              <h4>
                <strong>This Year</strong>
              </h4>
              <h4>2023</h4>
            </span>
            <span>
              <figure>
                <img src="assets/images/icons/chart-02.png" alt="" />
                <figcaption>
                  <h4>4234 User(s)</h4>
                  <h3>3243 Relay(s)</h3>
                </figcaption>
              </figure>
            </span>
          </div>
        </article>
        <article className="navitem item-3" onClick={() => handleNavClick(3)}>
          <div>
            <span>
              <h4>
                <strong>Prompts Comparison</strong>
              </h4>
              <h4>March 2023</h4>
            </span>
            <span>
              <figure>
                <img
                  src="assets/images/icons/chart-03.png"
                  alt=""
                  width="100"
                />
                <figcaption>
                  <h4>2332 User(s)</h4>
                  <h4>1023 Prompt(s)</h4>
                </figcaption>
              </figure>
            </span>
          </div>
        </article>
        <article className="navitem item-4" onClick={() => handleNavClick(4)}>
          <div>
            <span>
              <h4>
                <strong>Prompts vs Relays</strong>
              </h4>
              <h4>March 2023</h4>
            </span>
            <span>
              <figure>
                <img src="assets/images/icons/chart-04.png" alt="" width="85" />
                <figcaption>
                  <h4>Prompts.: 23</h4>
                  <h4>Relays.: 343</h4>
                </figcaption>
              </figure>
            </span>
          </div>
        </article>
      </nav>
      <div className="margin-top-btm">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: "chart-container" }}
        />
      </div>
      <nav className="charity-home-chartnav">
        <article className="navitem item-5">
          <section>
            <span>
              <i className="fa-solid fa-person-running fa-5x"></i>
            </span>
            <span>
              <h4>Total Relays</h4>
              <h2>
                <strong>2343</strong>
              </h2>
            </span>
          </section>
        </article>
        <article className="navitem item-6">
          <section>
            <span>
              <i className="fa-solid fa-terminal fa-5x"></i>
            </span>
            <span>
              <h4>Total Prompts</h4>
              <h2>
                <strong>2342</strong>
              </h2>
            </span>
          </section>
        </article>
        <article className="navitem item-7">
          <section>
            <span>
              <i className="fa-solid fa-users fa-5x"></i>
            </span>
            <span>
              <h4>Prompts/User</h4>
              <h2>
                <strong>32423</strong>
              </h2>
            </span>
          </section>
        </article>
        <article className="navitem item-8">
          <section>
            <span>
              <i className="fa-solid fa-chart-simple fa-5x"></i>
            </span>
            <span>
              <h4>Prompts/Relay</h4>
              <h2>
                <strong>3424</strong>
              </h2>
            </span>
          </section>
        </article>
      </nav>
    </section>
  );
}

export default Dashboard;
