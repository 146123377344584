import { usePagination, DOTS } from "../../hooks/usePagination";
import classes from "./Paginator.module.css";

function Paginator(props) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === lastPage) return null;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return null;
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classes.paginator}>
      <li
        key="first"
        className={
          currentPage === 1
            ? [classes.first, classes.disabled, classes.active].join(" ")
            : classes.first
        }
        onClick={onPrevious}
      />
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className={classes.disabled}>
              &#8230;
            </li>
          );
        }
        return (
          <li
            key={index}
            className={pageNumber === currentPage ? classes.active : ""}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        key="last"
        className={
          currentPage === lastPage
            ? [classes.last, classes.disabled, classes.active].join(" ")
            : classes.last
        }
        onClick={onNext}
      />
    </ul>
  );
}

export default Paginator;
