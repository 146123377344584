import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../store/AppContext";

function UserPwdChange() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const userMe = JSON.parse(localStorage.getItem("userMe"));
    const data = {
      user_id: userMe.user_id,
      current_password: currentPasswordRef.current.value,
      new_password: newPasswordRef.current.value,
      confirm_password: confirmPasswordRef.current.value,
    };

    fetch("/api/users/password_change.php", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.removeItem("userMe");
          return navigate("/", { state: { path: "Loging" } });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        appContext.showAlert(data);
      });
  };

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("userMe"));
    if (!userMe) return navigate("/", { state: { path: "Login" } });
  }, [navigate]);

  return (
    <section className="container">
      <div className="input-wrapper">
        <form onSubmit={handleFormSubmit}>
          <div className="input-inline">
            <div className="box-title">
              <h3>Change Password</h3>
            </div>
            <div>
              <label>
                <span>Current Password:</span>
                <input
                  type="password"
                  placeholder="Current password"
                  required
                  ref={currentPasswordRef}
                />
              </label>
              <aside>* Required</aside>
            </div>
            <div>
              <label>
                <span>New password:</span>
                <input
                  type="password"
                  placeholder="New password"
                  required
                  ref={newPasswordRef}
                />
              </label>
              <aside>* Required</aside>
            </div>
            <div>
              <label>
                <span>Confirm Password:</span>
                <input
                  type="password"
                  placeholder="Retype password"
                  required
                  ref={confirmPasswordRef}
                />
              </label>
              <aside>* Required</aside>
            </div>
            <div>
              <label>
                <span></span>
                <button>Change</button>
              </label>
              <aside>
                <button type="reset">Clear</button>
              </aside>
            </div>
            <div className="center">
              Require: 2 Upper, 2 Lower, 2 Numeric and 1 Special characters
              <br /> Allowed Characters: !@#$%^&*()\-_+.
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default UserPwdChange;
