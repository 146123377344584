import { useLocation } from "react-router-dom";

function AppHeader() {
  const location = useLocation();

  return (
    <header className="page-header">
      <figure className="logo">
        <i className="fa-solid fa-sliders"></i>
      </figure>
      <div className="location">
        <i className="fa-solid fa-house"></i>/
        {location.state?.path ? location.state?.path : "Login"}
      </div>
      <nav className="top-nav">
        <ul>
          <li className="message">
            <i className="fa-solid fa-envelope"></i>
          </li>
          <li className="bell">
            <i className="fa-solid fa-bell"></i>
          </li>
          <li className="login">
            <i className="fa-solid fa-right-to-bracket"></i>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default AppHeader;
