import { useContext, useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import AppHeader from "./components/layout/AppHeader";
import AppMain from "./components/layout/AppMain";
import SmartAlert from "./components/smartalert/SmartAlert";
import AppContext from "./store/AppContext";

function App() {
  const showAlert = useRef(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    //const userMe = sessionStorage.getItem("userMe");
    //if (!userMe) {
    //return navigate("/login", { state: { path: "Login" } });
    //}
    //console.log(JSON.parse(userMe));
    const { status_type, status_msgs } = appContext.alertData;
    showAlert.current({ type: status_type, alerts: status_msgs });
  }, [appContext.alertData]);

  return (
    <BrowserRouter>
      <SmartAlert showAlert={showAlert}></SmartAlert>
      <AppHeader />
      <AppMain />
    </BrowserRouter>
  );
}

export default App;
