import { Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Dashboard from "../../pages/Dashboard";
import UserAdd from "../..//pages/UserAdd";
import UserList from "../../pages/UserList";
import UserListActive from "../../pages/UserListActive";
import UserListInactive from "../../pages/UserListInactive";
import UserListDeleted from "../../pages/UserListDeleted";
import UserPwdChange from "../../pages/UserPwdChange";
import UserLogin from "../../pages/UserLogin";

function AppMain() {
  return (
    <main className="page-content">
      <Sidebar />
      <Routes>
        <Route path="/">
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="user_add" element={<UserAdd />} />
          <Route path="user_list" element={<UserList />} />
          <Route path="user_list_active" element={<UserListActive />} />
          <Route path="user_list_inactive" element={<UserListInactive />} />
          <Route path="user_list_deleted" element={<UserListDeleted />} />
          <Route path="user_pwd_change" element={<UserPwdChange />} />
          <Route index element={<UserLogin />} />
        </Route>
      </Routes>
    </main>
  );
}

export default AppMain;
