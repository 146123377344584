import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paginator from "../components/paginator/Paginator";
import Modal from "../components/modal/Modal";
import AppContext from "../store/AppContext";

function UserList() {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const filterColumnRef = useRef();
  const filterValueRef = useRef();
  const sortColumnRef = useRef();
  const sortValueRef = useRef("ASC");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [siblingCount] = useState(1);
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();

  const handleEditClick = (userID) => {
    const user = listData.find((item) => item.user_id === userID);
    navigate("/user_add", { state: { path: "Add User", user: user } });
  };

  const handleToggleClick = (userID) => {
    const user = listData.find((item) => item.user_id === userID);
    user.status = parseInt(user.status) === 1 ? 2 : 1;
    user.custom_text = parseInt(user.status) === 1 ? "Active" : "Inactive";
    //console.log(user);
    fetch("/api/users/add.php", {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 403) {
          appContext.setUserMe({});
          localStorage.removeItem("userMe");
          return navigate("/", { state: { path: "Login" } });
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        appContext.showAlert(data);
      });
  };

  const loadListData = useCallback(() => {
    setIsLoading(true);
    const query = new URLSearchParams({
      position: currentPage - 1,
      size: pageSize,
      filtercolumn: filterColumnRef.current.value,
      filtervalue: filterValueRef.current.value,
      sortcolumn: sortColumnRef.current.value,
      sort: sortValueRef.current,
    });

    fetch("/api/users/list.php?".concat(query))
      .then((response) => {
        if (response.status === 403) {
          localStorage.removeItem("userMe");
          return navigate("/", { state: { path: "Login" } });
        }
        return response.json();
      })
      .then((data) => {
        if (!data.status_data) return;
        const { total_count, list_data } = data.status_data;
        setTotalCount(total_count);
        setListData(list_data);
        setIsLoading(false);
      });
  }, [currentPage, pageSize, navigate]);

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("userMe"));
    if (!userMe) return navigate("/", { state: { path: "Login" } });
    loadListData();
  }, [loadListData, navigate]);

  return (
    <section className="container">
      {showModal && (
        <Modal
          state={{
            title: "Delete User",
            message: "Are you sure you want to delete?",
            confirmBtnTitle: "Confirm",
            cancelBtnTitle: "Cancel",
            onCancel: () => setShowModal(false),
          }}
        />
      )}
      <fieldset id="gridOptions">
        <ul>
          <li>
            <label>
              <span>Look in:</span>
              <select ref={filterColumnRef}>
                <option value={""}>--Select--</option>
                <option value={"fullname"}>Full Name</option>
                <option value={"username"}>Username</option>
                <option value={"status"}>Status</option>
                <option value={"notes"}>Notes</option>
              </select>
            </label>
            <label>
              <input
                type="search"
                placeholder="Search for..."
                ref={filterValueRef}
              />
            </label>
            <button onClick={loadListData}>Search</button>
          </li>
          <li>
            <div>
              <label>
                <span>Sort:</span>
                <select ref={sortColumnRef}>
                  <option value={""}>Default</option>
                  <option value={"fullname"}>Full Name</option>
                  <option value={"username"}>Username</option>
                  <option value={"relays"}>Relays</option>
                  <option value={"prompts"}>Prompts</option>
                  <option value={"expiry"}>Expiry</option>
                  <option value={"signup"}>Signup</option>
                  <option value={"active"}>Active</option>
                  <option value={"status"}>Status</option>
                  <option value={"notes"}>Notes</option>
                </select>
              </label>
              <label>
                <input
                  type="radio"
                  name="sort"
                  value="ASC"
                  defaultChecked={true}
                  onClick={() => (sortValueRef.current = "ASC")}
                />
                <i className="fa-solid fa-sort-alpha-down"></i>
              </label>
              <label>
                <input
                  type="radio"
                  name="sort"
                  value="DESC"
                  onClick={() => (sortValueRef.current = "DESC")}
                />
                <i className="fa-solid fa-sort-alpha-up"></i>
              </label>
              <button onClick={loadListData}>Sort</button>
            </div>
          </li>
        </ul>
        <ul>
          <li>
            {totalCount >= 25 && (
              <Paginator
                totalCount={totalCount}
                currentPage={currentPage}
                pageSize={pageSize}
                siblingCount={siblingCount}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </li>
          <li>
            <label>
              <span>Row Count:</span>
              <select name="items_per_group">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </label>
            <button>Set</button>
          </li>
        </ul>
      </fieldset>
      {isLoading ? (
        <div className="spinner">
          <i className="fa-brands fa-gg fa-pulse fa-3x"></i>
          <span>Loading...</span>
        </div>
      ) : (
        <>
          {!listData ? (
            <div className="margin-top">Info: No records found</div>
          ) : (
            <div className="data-grid margin-top">
              {listData.map((item) => (
                <div className="data-list" key={item.user_id}>
                  <ul className="three">
                    <li>
                      <label>Full Name:</label>
                      {`${item.first_name} ${item.last_name} | ${item.custom_text}`}
                    </li>
                    <li>
                      <label>Username:</label>
                      {item.email}
                    </li>
                    <li>
                      <label>Expiry:</label>
                      {new Date(item.expireson).toLocaleDateString()}
                    </li>
                  </ul>
                  <ul className="three">
                    <li>
                      <label>Relays:</label>
                      {item.relays}
                    </li>
                    <li>
                      <label>Prompts:</label>
                      {item.prompts}
                    </li>
                    <li>
                      <label>Last Active:</label>
                      {item.last_active &&
                        new Date(item.last_active).toLocaleString()}
                    </li>
                  </ul>
                  <ul className="three">
                    <li>
                      <label>Signup:</label>
                      {new Date(item.signup).toLocaleDateString()}
                    </li>
                    <li>
                      <label>Notes:</label>
                      {item.notes}
                    </li>
                    <li className="action">
                      <i
                        className="fa-solid fa-trash-alt"
                        onClick={() => setShowModal(true)}
                      ></i>
                      <i
                        className="fa-solid fa-pencil-alt"
                        onClick={() => handleEditClick(item.user_id)}
                      ></i>
                      <label className="switch">
                        <input
                          type="checkbox"
                          defaultChecked={
                            parseInt(item.status) === 1 ? true : false
                          }
                          onClick={() => handleToggleClick(item.user_id)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
}

export default UserList;
