//UserAdd.jsx
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../store/AppContext";

function UserAdd() {
  const appContext = useContext(AppContext);
  const [userStatus, setUserStatus] = useState(true);
  const userIDRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const expiresOnRef = useRef(null);
  const statusRef = useRef(null);
  const notesRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  /*
  const handleNewClick = () => {
    userIDRef.current = null;
    firstNameRef.current.value = null;
    lastNameRef.current.value = null;
    emailRef.current.value = null;
    expiresOnRef.current.value = null;
    statusRef.current.checked = true;
    setUserStatus(true);
    notesRef.current.value = null;
  };
*/
  const handleCloseClick = () => {
    navigate("/user_list", { state: { path: "Registered Emails" } });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const user_id = userIDRef.current;
    let user = {
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      email: emailRef.current.value,
      expireson: expiresOnRef.current.value,
      status: statusRef.current.checked ? 1 : 2,
      notes: notesRef.current.value,
    };
    //console.log(user);
    switch (user_id !== undefined && user_id !== null) {
      //edit user
      case true:
        user = { ...user, ...{ user_id: user_id } };
        fetch("/api/users/add.php", {
          method: "PUT",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 403) {
              localStorage.removeItem("userMe");
              return navigate("/login", { state: { path: "Login" } });
            }
            return response.json();
          })
          .then((data) => {
            //console.log(data);
            appContext.showAlert(data);
          });
        break;
      //default add user
      default:
        fetch("/api/users/add.php", {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 403) {
              localStorage.removeItem("userMe");
              return navigate("/", { state: { path: "Login" } });
            }
            return response.json();
          })
          .then((data) => {
            //console.log(data);
            const { status_type, status_msgs } = data;
            appContext.showAlert({
              status_type: status_type,
              status_msgs: status_msgs,
            });

            if (status_type === "success")
              userIDRef.current = data.status_data.userid;
          });
    }
  };

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("userMe"));
    if (!userMe) return navigate("/", { state: { path: "Login" } });
    const { user } = state;
    if (user) {
      userIDRef.current = user.user_id;
      firstNameRef.current.value = user.first_name;
      lastNameRef.current.value = user.last_name;
      emailRef.current.value = user.email;
      expiresOnRef.current.value = user.expireson;
      statusRef.current.checked = parseInt(user.status) === 1 ? true : false;
      setUserStatus(() => (parseInt(user.status) === 1 ? true : false));
      notesRef.current.value = user.notes;
    }
  }, [state, navigate]);

  return (
    <section className="container">
      <div className="input-wrapper">
        <form onSubmit={handleFormSubmit}>
          <div className="input-inline add-user">
            <div className="box-title">
              <h3>Add/Edit User</h3>
            </div>
            <div>
              <label>
                <span>First name:</span>
                <input
                  type="text"
                  placeholder="Enter first name"
                  required
                  ref={firstNameRef}
                />
              </label>
              <aside>e.g John</aside>
            </div>
            <div>
              <label>
                <span>Last name:</span>
                <input
                  type="text"
                  placeholder="Enter last name"
                  required
                  ref={lastNameRef}
                />
              </label>
              <aside>e.g. Morgans</aside>
            </div>
            <div>
              <label>
                <span>Email:</span>
                <input
                  type="email"
                  placeholder="Enter email"
                  required
                  ref={emailRef}
                />
              </label>
              <aside>e.g. user@domain.com</aside>
            </div>
            <div>
              <label>
                <span>Expires On:</span>
                <input
                  type="date"
                  placeholder="01/23/2024"
                  required
                  ref={expiresOnRef}
                />
              </label>
              <aside>e.g. 03/09/2030</aside>
            </div>
            <div>
              <label>
                <span>Account Status:</span>
                <div className="switch">
                  <input
                    type="checkbox"
                    defaultChecked="true"
                    ref={statusRef}
                    onClick={() => {
                      setUserStatus((prevStatus) => {
                        return prevStatus ? false : true;
                      });
                    }}
                  />
                  <div className="slider round"></div>
                </div>
              </label>
              <aside>{userStatus ? "Active" : "Inactive"}</aside>
            </div>
            <div>
              <label>
                <span>Notes:</span>
                <textarea placeholder="Enter notes...." ref={notesRef} />
              </label>
            </div>
            <div className="center">
              <button>Save</button>
              <button type="reset">New</button>
              <button type="button" onClick={handleCloseClick}>
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default UserAdd;
