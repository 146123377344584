import { createContext, useState } from "react";

const AppContext = createContext({
  alertData: {},
  showAlert: (alertData) => {},
  userMe: {},
  setUserMe: (userMe) => {},
});

export function AppContextProvider(props) {
  //display alerts to user
  const [alertData, setAlertData] = useState({});
  const [userMe, setUserMe] = useState({});

  //handle showAlert
  const setShowAlertHandler = (alertData) => {
    setAlertData(alertData);
  };

  //handle setUserMe
  const setUserMeHandler = (userMe) => {
    setUserMe(userMe);
  };

  //app context
  const context = {
    alertData: alertData,
    showAlert: setShowAlertHandler,
    userMe: userMe,
    setUserMe: setUserMeHandler,
  };

  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
}

export default AppContext;
