import classes from "./Modal.module.css";
function Modal(props) {
  const {
    title,
    message,
    confirmBtnTitle,
    cancelBtnTitle,
    onConfirm,
    onCancel,
  } = props.state;

  return (
    <>
      <div className={classes.modal}>
        <header className={classes.title}>{title}</header>
        <p className={classes.message}>{message}</p>
        <footer className={classes.action}>
          <button
            className={`btn-alt ${classes.cancel}`}
            onClick={onCancel && onCancel}
          >
            {cancelBtnTitle}
          </button>
          <button className={classes.confirm} onClick={onConfirm}>
            {confirmBtnTitle}
          </button>
        </footer>
      </div>
      <div className={classes.backdrop} onClick={onCancel && onCancel} />
    </>
  );
}

export default Modal;
