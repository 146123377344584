import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AppContext from "../../store/AppContext";

function Sidebar() {
  //const [user, setUser] = useState({});
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const userMe =
    JSON.parse(localStorage.getItem("userMe")) || appContext.userMe;

  const handleNavLinkClick = (event) => {
    event.preventDefault();
    //console.log(event);
    const href = event.target.pathname;
    const path = event.target.text;
    //console.log(href, path, user);
    if (Object.keys(userMe).length === 0) {
      return navigate("/", { state: { path: "Login" } });
    }

    switch (href) {
      case "/logout":
        fetch("/api/users/logout.php")
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            appContext.setUserMe({});
            localStorage.removeItem("userMe");
            return navigate("/", { state: { path: "Login" } });
          });
        break;
      default:
        return navigate(href, { state: { path: path } });
    }
  };

  return (
    <aside className="sidebar">
      <ul className="side-nav">
        <li>
          <NavLink
            to="/dashboard"
            state={{ path: "Dashboard" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-gauge-high"></i>Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/user_add"
            state={{ path: "Add User" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-user-plus"></i>Add User
          </NavLink>
        </li>
        <li>
          <NavLink
            to="user_list"
            state={{ path: "Registered Emails" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-envelopes-bulk"></i>Registered Emails
          </NavLink>
        </li>
        <li>
          <NavLink
            to="user_list_active"
            state={{ path: "Allowed List" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-check-double"></i>Allowed List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="user_list_inactive"
            state={{ path: "Inactive List" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-ban"></i>Inactive List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="user_list_deleted"
            state={{ path: "Deleted List" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-user-slash"></i>Deleted List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="user_pwd_change"
            state={{ path: "Change Password" }}
            onClick={handleNavLinkClick}
          >
            <i className="fa-solid fa-key"></i>Change Password
          </NavLink>
        </li>
        {Object.keys(userMe).length === 0 ? (
          <li>
            <NavLink
              to="/"
              state={{ path: "Login" }}
              onClick={handleNavLinkClick}
            >
              <i className="fa-solid fa-arrow-right-to-bracket"></i>Login
            </NavLink>
          </li>
        ) : (
          <li>
            <NavLink to="logout" onClick={handleNavLinkClick}>
              <i className="fa-solid fa-arrow-right-from-bracket"></i>Logout
            </NavLink>
          </li>
        )}
      </ul>
    </aside>
  );
}

export default Sidebar;
